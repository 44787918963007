@import "../../../../public/assets/scss/tokens.scss";

.feed-group-management-table {
  &__link {
    color: $info;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
