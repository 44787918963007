@import "../../../../public/assets/scss/tokens.scss";

.schedule-modal {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $spacing-size-xl;
  }

  &__select {
    min-width: 250px;
    height: 56px;
    margin-bottom: $spacing-size-sm;
  }
  
  &__date-picker {
    background: transparent;
  }
}
