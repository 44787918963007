@import "../../../../../public/assets/scss/tokens.scss";

.feed-forecast-feed-group-table {
  margin-top: $spacing-size-xl;
  &__summary-order {
    cursor: pointer;
    &:hover {
      color: $info !important;
    }
  }
  &__details {
    cursor: pointer;

    &:hover {
      color: $info !important;
    }
  }
  &__row--success {
    background-color: $success !important;
    color: $primary-white !important;
  }
  &__row--warning {
    background-color: $warning !important;
    color: $primary-white !important;
  }
  &__row--danger {
    background-color: $danger !important;
    color: $primary-white !important;
  }
  &__row--empty {
    // border-left: 1px solid $light;
  }
}
