@import "../../../../public/assets/scss/tokens.scss";

.modified {
  font-weight: $font-weight-lg !important;
  font-style: italic;
}

.editable-field {
  &__input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-size-xs;
    border: none;
    background-color: #f8f8f8;
    width: 100%;
  }
}
