@import "../../../../public/assets/scss/tokens.scss";

.feed-orders-table {
  margin-top: calc($spacing-size-xxl * 2);

  &__pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-size-md;
  }
}
