@import "../../../public/assets/scss/tokens.scss";

.feed-orders-management {
  &__actions {
    display: flex;
    align-items: center;
    gap: $spacing-size-md;
    margin-top: $spacing-size-lg;
  }
}
